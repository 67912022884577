import * as CookieConsent from 'vanilla-cookieconsent'
import type { CookieConsentConfig } from 'vanilla-cookieconsent'

export default defineNuxtPlugin(async () => {
  const { isCrawler } = useDevice()
  if (isCrawler) return

  const consent = useState('consent')
  const route = useRoute()
  const locale = route.params.locale
  const config: CookieConsentConfig = {
    guiOptions: {
      consentModal: {
        layout: 'cloud inline',
        position: 'bottom',
        equalWeightButtons: false,
        flipButtons: true,
      },
      preferencesModal: {
        layout: 'box',
        position: 'right',
        equalWeightButtons: false,
        flipButtons: false,
      },
    },
    categories: {
      necessary: {
        readOnly: true,
      },
      functionality: {},
      analytics: {},
      marketing: {},
    },
    language: {
      default: locale,
      translations: {
        es: {
          consentModal: {
            title: 'Esta página web usa cookies',
            description:
              'Las cookies de este sitio web se usan para personalizar el contenido y los anuncios, ofrecer funciones de redes sociales y analizar el tráfico. Además, compartimos información sobre el uso que haga del sitio web con nuestros partners de redes sociales, publicidad y análisis web, quienes pueden combinarla con otra información que les haya proporcionado o que hayan recopilado a partir del uso que haya hecho de sus servicios.',
            acceptAllBtn: 'Aceptar todo',
            acceptNecessaryBtn: 'Rechazar todo',
            showPreferencesBtn: 'Gestionar preferencias',
            footer:
              '<a href="#link">Política de privacidad</a>\n<a href="#link">Términos y condiciones</a>',
          },
          preferencesModal: {
            title: 'Preferencias de Consentimiento',
            closeIconLabel: 'Cerrar modal',
            acceptAllBtn: 'Aceptar todo',
            acceptNecessaryBtn: 'Rechazar todo',
            savePreferencesBtn: 'Guardar preferencias',
            serviceCounterLabel: 'Servicios',
            sections: [
              {
                title: 'Uso de Cookies',
                description:
                  'Las cookies son pequeños archivos de texto que las páginas web pueden utilizar para hacer más eficiente la experiencia del usuario. </br></br> La ley afirma que podemos almacenar cookies en su dispositivo si son estrictamente necesarias para el funcionamiento de esta página. Para todos los demás tipos de cookies necesitamos su permiso. Esto significa que las cookies que están categorizadas como necesarias se tratan con base en RGPD Art. 6(1)(f). El resto de cookies, es decir, aquellas de las categorías de preferencias y marketing, son tratadas con base en RGPD Art. 6(1)(a).',
              },
              {
                title:
                  'Cookies Estrictamente Necesarias <span class="pm__badge">Siempre Habilitado</span>',
                description:
                  'Las cookies necesarias ayudan a hacer una página web utilizable activando funciones básicas como la navegación en la página y el acceso a áreas seguras de la página web. La página web no puede funcionar adecuadamente sin estas cookies.',
                linkedCategory: 'necessary',
              },
              {
                title: 'Funcionalidad',
                description:
                  'Las cookies de funcionalidad permiten a la página web recordar información que cambia la forma en que la página se comporta o el aspecto que tiene, como su idioma preferido o la región en la que usted se encuentra.',
                linkedCategory: 'functionality',
              },
              {
                title: 'Analítica',
                description:
                  'Las cookies de analítica ayudan a los propietarios de páginas web a comprender cómo interactúan los visitantes con las páginas web reuniendo y proporcionando información de forma anónima.',
                linkedCategory: 'analytics',
              },
              {
                title: 'Marketing',
                description:
                  'Las cookies de marketing se utilizan para rastrear a los visitantes en las páginas web. La intención es mostrar anuncios relevantes y atractivos para el usuario individual, y por lo tanto, más valiosos para los editores y terceros anunciantes.',
                linkedCategory: 'marketing',
              },
              {
                title: 'Más información',
                description:
                  'Esta página utiliza tipos diferentes de cookies. Algunas cookies son colocadas por servicios de terceros que aparecen en nuestras páginas. </br></br> En cualquier momento puede cambiar o retirar su consentimiento desde la <a class="cc__link" href="/">Declaración de cookies</a> en nuestro sitio web. </br></br> Obtenga más información sobre quiénes somos, cómo puede contactarnos y cómo procesamos los datos personales en nuestra <a class="cc__link" href="/">Política de privacidad</a>. </br></br> Al contactarnos respecto a su consentimiento, por favor, indique el ID y la fecha de su consentimiento.',
              },
            ],
          },
        },
        en: {
          consentModal: {
            title: 'This website uses cookies',
            description:
              'Cookies on this website are used to personalize content and ads, provide social media features, and analyze traffic. In addition, we share information about your use of the website with our social media, advertising, and web analytics partners, who may combine it with other information that you have provided to them or that they have collected from your use of their services.',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage preferences',
            footer:
              '<a href="#link">Privacy policy</a>\n<a href="#link">Terms and conditions</a>',
          },
          preferencesModal: {
            title: 'Consent Preferences',
            closeIconLabel: 'Close modal',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Save preferences',
            serviceCounterLabel: 'Services',
            sections: [
              {
                title: 'Use of Cookies',
                description:
                  'Cookies are small text files that websites can use to make the user experience more efficient. </br></br> The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission. This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.',
              },
              {
                title:
                  'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                description:
                  'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
                linkedCategory: 'necessary',
              },
              {
                title: 'Preferences',
                description:
                  'Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.',
                linkedCategory: 'functionality',
              },
              {
                title: 'Statistics',
                description:
                  'Statistical cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.',
                linkedCategory: 'analytics',
              },
              {
                title: 'Marketing',
                description:
                  'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                linkedCategory: 'marketing',
              },
              {
                title: 'More information',
                description:
                  'At any time you can change or withdraw your consent from the <a class="cc__link" href="/">Cookie Declaration</a> on our website. </br></br> Learn more about who we are, how you can contact us, and how we process personal data in our <a class="cc__link" href="/">Privacy Policy</a>. </br></br> When contacting us regarding your consent, please state your consent ID and date.',
              },
            ],
          },
        },
        fr: {
          consentModal: {
            title: 'Ce site web utilise des cookies',
            description:
              "Les cookies de ce site web sont utilisés pour personnaliser le contenu et les annonces, offrir des fonctionnalités de médias sociaux et analyser le trafic. De plus, nous partageons des informations sur l'utilisation du site web avec nos partenaires de médias sociaux, de publicité et d'analyse web, qui peuvent les combiner avec d'autres informations que vous leur avez fournies ou qu'ils ont collectées lors de votre utilisation de leurs services.",
            acceptAllBtn: 'Tout accepter',
            acceptNecessaryBtn: 'Tout refuser',
            showPreferencesBtn: 'Gérer les préférences',
            footer:
              '<a href="#link">Politique de confidentialité</a>\n<a href="#link">Conditions d\'utilisation</a>',
          },
          preferencesModal: {
            title: 'Préférences de consentement',
            closeIconLabel: 'Fermer la fenêtre modale',
            acceptAllBtn: 'Tout accepter',
            acceptNecessaryBtn: 'Tout refuser',
            savePreferencesBtn: 'Enregistrer les préférences',
            serviceCounterLabel: 'Services',
            sections: [
              {
                title: 'Utilisation des cookies',
                description:
                  "Les cookies sont de petits fichiers texte que les sites web peuvent utiliser pour rendre l'expérience de l'utilisateur plus efficace. </br></br> La loi stipule que nous pouvons stocker des cookies sur votre appareil s'ils sont strictement nécessaires au fonctionnement de ce site. Pour tous les autres types de cookies, nous avons besoin de votre autorisation. Ce site utilise différents types de cookies. Certains cookies sont placés par des services tiers qui apparaissent sur nos pages.",
              },
              {
                title:
                  'Cookies strictement nécessaires <span class="pm__badge">Toujours activé</span>',
                description:
                  "Les cookies nécessaires contribuent à rendre un site web utilisable en activant des fonctions de base comme la navigation dans les pages et l'accès à des zones sécurisées du site web. Le site web ne peut pas fonctionner correctement sans ces cookies.",
                linkedCategory: 'necessary',
              },
              {
                title: 'Préférences',
                description:
                  "Les cookies de préférences permettent à un site web de mémoriser des informations qui modifient le comportement ou l'apparence du site, comme votre langue préférée ou la région dans laquelle vous vous trouvez.",
                linkedCategory: 'functionality',
              },
              {
                title: 'Statistiques',
                description:
                  'Les cookies statistiques aident les propriétaires de sites web à comprendre comment les visiteurs interagissent avec les sites web en collectant et en fournissant des informations de manière anonyme.',
                linkedCategory: 'analytics',
              },
              {
                title: 'Marketing',
                description:
                  "Les cookies marketing sont utilisés pour suivre les visiteurs sur les sites web. L'objectif est d'afficher des publicités qui sont pertinentes et engageantes pour l'utilisateur individuel et donc plus précieuses pour les éditeurs et les annonceurs tiers.",
                linkedCategory: 'marketing',
              },
              {
                title: "Plus d'informations",
                description:
                  'À tout moment, vous pouvez modifier ou retirer votre consentement depuis la <a class="cc__link" href="/">Déclaration de cookies</a> sur notre site web. </br></br> En savoir plus sur qui nous sommes, comment vous pouvez nous contacter et comment nous traitons les données personnelles dans notre <a class="cc__link" href="/">Politique de confidentialité</a>. </br></br> Lorsque vous nous contactez concernant votre consentement, veuillez indiquer votre ID de consentement et la date.',
              },
            ],
          },
        },
        pt: {
          consentModal: {
            title: 'Este site usa cookies',
            description:
              'Os cookies neste site são usados para personalizar o conteúdo e os anúncios, fornecer recursos de mídia social e analisar o tráfego. Além disso, compartilhamos informações sobre o uso do site com nossos parceiros de mídia social, publicidade e análise da web, que podem combiná-las com outras informações que você forneceu a eles ou que eles coletaram do uso de seus serviços.',
            acceptAllBtn: 'Aceitar tudo',
            acceptNecessaryBtn: 'Rejeitar tudo',
            showPreferencesBtn: 'Gerenciar preferências',
            footer:
              '<a href="#link">Política de privacidade</a>\n<a href="#link">Termos e condições</a>',
          },
          preferencesModal: {
            title: 'Preferências de Consentimento',
            closeIconLabel: 'Fechar modal',
            acceptAllBtn: 'Aceitar tudo',
            acceptNecessaryBtn: 'Rejeitar tudo',
            savePreferencesBtn: 'Salvar preferências',
            serviceCounterLabel: 'Serviços',
            sections: [
              {
                title: 'Uso de Cookies',
                description:
                  'Cookies são pequenos arquivos de texto que os sites podem usar para tornar a experiência do usuário mais eficiente. </br></br> A lei afirma que podemos armazenar cookies em seu dispositivo se forem estritamente necessários para o funcionamento deste site. Para todos os outros tipos de cookies, precisamos de sua permissão. Este site usa diferentes tipos de cookies. Alguns cookies são colocados por serviços de terceiros que aparecem em nossas páginas.',
              },
              {
                title:
                  'Cookies Estritamente Necessários <span class="pm__badge">Sempre Ativado</span>',
                description:
                  'Os cookies necessários ajudam a tornar um site utilizável, ativando funções básicas como a navegação na página e o acesso a áreas seguras do site. O site não pode funcionar corretamente sem esses cookies.',
                linkedCategory: 'necessary',
              },
              {
                title: 'Preferências',
                description:
                  'Os cookies de preferência permitem que um site se lembre de informações que mudam a forma como o site se comporta ou se parece, como seu idioma preferido ou a região em que você está.',
                linkedCategory: 'functionality',
              },
              {
                title: 'Estatísticas',
                description:
                  'Os cookies estatísticos ajudam os proprietários de sites a entender como os visitantes interagem com os sites, coletando e relatando informações anonimamente.',
                linkedCategory: 'analytics',
              },
              {
                title: 'Marketing',
                description:
                  'Os cookies de marketing são usados para rastrear visitantes em sites. A intenção é exibir anúncios que sejam relevantes e envolventes para o usuário individual e, portanto, mais valiosos para editores e anunciantes terceirizados.',
                linkedCategory: 'marketing',
              },
              {
                title: 'Mais informações',
                description:
                  'A qualquer momento, você pode alterar ou retirar seu consentimento da <a class="cc__link" href="/">Declaração de cookies</a> em nosso site. </br></br> Saiba mais sobre quem somos, como você pode nos contatar e como processamos dados pessoais em nossa <a class="cc__link" href="/">Política de Privacidade</a>. </br></br> Ao entrar em contato conosco sobre seu consentimento, por favor, informe seu ID de consentimento e a data.',
              },
            ],
          },
        },
        de: {
          consentModal: {
            title: 'Diese Website verwendet Cookies',
            description:
              'Cookies auf dieser Website werden verwendet, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien bereitzustellen und den Datenverkehr zu analysieren. Außerdem geben wir Informationen zu Ihrer Nutzung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.',
            acceptAllBtn: 'Alle akzeptieren',
            acceptNecessaryBtn: 'Alle ablehnen',
            showPreferencesBtn: 'Einstellungen verwalten',
            footer:
              '<a href="#link">Datenschutzerklärung</a>\n<a href="#link">Nutzungsbedingungen</a>',
          },
          preferencesModal: {
            title: 'Einwilligungspräferenzen',
            closeIconLabel: 'Modal schließen',
            acceptAllBtn: 'Alle akzeptieren',
            acceptNecessaryBtn: 'Alle ablehnen',
            savePreferencesBtn: 'Einstellungen speichern',
            serviceCounterLabel: 'Dienste',
            sections: [
              {
                title: 'Verwendung von Cookies',
                description:
                  'Cookies sind kleine Textdateien, die Websites verwenden können, um die Benutzererfahrung effizienter zu gestalten. </br></br> Das Gesetz besagt, dass wir Cookies auf Ihrem Gerät speichern können, wenn sie für den Betrieb dieser Website unbedingt erforderlich sind. Für alle anderen Arten von Cookies benötigen wir Ihre Erlaubnis. Diese Website verwendet verschiedene Arten von Cookies. Einige Cookies werden von Drittanbieterdiensten platziert, die auf unseren Seiten erscheinen.',
              },
              {
                title:
                  'Unbedingt erforderliche Cookies <span class="pm__badge">Immer aktiviert</span>',
                description:
                  'Notwendige Cookies helfen dabei, eine Website nutzbar zu machen, indem sie grundlegende Funktionen wie die Seitennavigation und den Zugriff auf sichere Bereiche der Website ermöglichen. Die Website kann ohne diese Cookies nicht ordnungsgemäß funktionieren.',
                linkedCategory: 'necessary',
              },
              {
                title: 'Präferenzen',
                description:
                  'Präferenz-Cookies ermöglichen es einer Website, Informationen zu speichern, die das Verhalten oder das Aussehen der Website ändern, wie Ihre bevorzugte Sprache oder die Region, in der Sie sich befinden.',
                linkedCategory: 'functionality',
              },
              {
                title: 'Statistiken',
                description:
                  'Statistik-Cookies helfen Website-Besitzern zu verstehen, wie Besucher mit Websites interagieren, indem sie Informationen anonym sammeln und melden.',
                linkedCategory: 'analytics',
              },
              {
                title: 'Marketing',
                description:
                  'Marketing-Cookies werden verwendet, um Besucher über Websites hinweg zu verfolgen. Die Absicht ist es, Anzeigen zu zeigen, die relevant und ansprechend für den einzelnen Benutzer sind und daher wertvoller für Publisher und werbetreibende Drittparteien sind.',
                linkedCategory: 'marketing',
              },
              {
                title: 'Weitere Informationen',
                description:
                  'Sie können Ihre Einwilligung jederzeit ändern oder widerrufen, indem Sie auf die <a class="cc__link" href="/">Cookie-Erklärung</a> auf unserer Website klicken. </br></br> Erfahren Sie mehr darüber, wer wir sind, wie Sie uns kontaktieren können und wie wir personenbezogene Daten verarbeiten in unserer <a class="cc__link" href="/">Datenschutzrichtlinie</a>. </br></br> Wenn Sie uns bezüglich Ihrer Einwilligung kontaktieren, geben Sie bitte Ihre Einwilligungs-ID und das Datum an.',
              },
            ],
          },
        },
        it: {
          consentModal: {
            title: 'Questo sito web utilizza i cookie',
            description:
              "I cookie su questo sito web vengono utilizzati per personalizzare i contenuti e gli annunci, fornire funzionalità di social media e analizzare il traffico. Inoltre, condividiamo informazioni sull'utilizzo del sito con i nostri partner di social media, pubblicità e analisi web, che possono combinarle con altre informazioni che hai fornito loro o che hanno raccolto dal tuo utilizzo dei loro servizi.",
            acceptAllBtn: 'Accetta tutto',
            acceptNecessaryBtn: 'Rifiuta tutto',
            showPreferencesBtn: 'Gestisci preferenze',
            footer:
              '<a href="#link">Informativa sulla privacy</a>\n<a href="#link">Termini e condizioni</a>',
          },
          preferencesModal: {
            title: 'Preferenze di consenso',
            closeIconLabel: 'Chiudi modale',
            acceptAllBtn: 'Accetta tutto',
            acceptNecessaryBtn: 'Rifiuta tutto',
            savePreferencesBtn: 'Salva preferenze',
            serviceCounterLabel: 'Servizi',
            sections: [
              {
                title: 'Utilizzo dei cookie',
                description:
                  "I cookie sono piccoli file di testo che i siti web possono utilizzare per rendere più efficiente l'esperienza dell'utente. </br></br> La legge stabilisce che possiamo memorizzare i cookie sul tuo dispositivo se sono strettamente necessari per il funzionamento di questo sito. Per tutti gli altri tipi di cookie abbiamo bisogno del tuo permesso. Questo sito utilizza diversi tipi di cookie. Alcuni cookie sono posizionati da servizi di terze parti che compaiono sulle nostre pagine.",
              },
              {
                title:
                  'Cookie strettamente necessari <span class="pm__badge">Sempre abilitato</span>',
                description:
                  "I cookie necessari aiutano a rendere un sito web utilizzabile abilitando funzioni di base come la navigazione della pagina e l'accesso alle aree protette del sito web. Il sito web non può funzionare correttamente senza questi cookie.",
                linkedCategory: 'necessary',
              },
              {
                title: 'Preferenze',
                description:
                  "I cookie delle preferenze consentono a un sito web di ricordare informazioni che modificano il comportamento o l'aspetto del sito, come la tua lingua preferita o la regione in cui ti trovi.",
                linkedCategory: 'functionality',
              },
              {
                title: 'Statistiche',
                description:
                  'I cookie statistici aiutano i proprietari di siti web a capire come i visitatori interagiscono con i siti raccogliendo e trasmettendo informazioni in forma anonima.',
                linkedCategory: 'analytics',
              },
              {
                title: 'Marketing',
                description:
                  "I cookie di marketing vengono utilizzati per tracciare i visitatori sui siti web. Lo scopo è quello di visualizzare annunci che siano rilevanti e coinvolgenti per l'utente individuale e quindi di maggior valore per gli editori e gli inserzionisti terzi.",
                linkedCategory: 'marketing',
              },
              {
                title: 'Ulteriori informazioni',
                description:
                  'In qualsiasi momento è possibile modificare o revocare il proprio consenso dalla <a class="cc__link" href="/">Dichiarazione sui cookie</a> sul nostro sito web. </br></br> Per saperne di più su chi siamo, su come contattarci e su come trattiamo i dati personali nella nostra <a class="cc__link" href="/">Informativa sulla privacy</a>. </br></br> Quando ci contatti in merito al tuo consenso, ti preghiamo di indicare il tuo ID di consenso e la data.',
              },
            ],
          },
        },
      },
    },
    disablePageInteraction: false,
    onFirstConsent: ({ cookie }) => {
      const preferences = CookieConsent.getUserPreferences()
      const analytics = preferences.acceptedCategories.includes('analytics')
        ? 'granted'
        : 'denied'
      const functionality = preferences.acceptedCategories.includes(
        'functionality'
      )
        ? 'granted'
        : 'denied'
      const marketing = preferences.acceptedCategories.includes('marketing')
        ? 'granted'
        : 'denied'

      console.debug('Update consent mode:')
      console.debug('Security consent      :', 'granted')
      console.debug('Funcionality consent  :', functionality)
      console.debug('Analytics consent     :', analytics)
      console.debug('Ads consent consent   :', marketing)

      consent.value = {
        security: true,
        functionality: functionality == 'granted',
        analytics: analytics == 'granted',
        marketing: marketing == 'granted',
      }

      gtag('consent', 'update', {
        security_storage: 'granted',
        personalization_storage: functionality,
        functionality_storage: functionality,
        analytics_storage: analytics,
        ad_storage: marketing,
        ad_user_data: marketing,
        ad_personalization: marketing,
      })
    },
    onChange: async ({ cookie, changedCategories, changedServices }) => {
      const preferences = CookieConsent.getUserPreferences()
      const analytics = preferences.acceptedCategories.includes('analytics')
        ? 'granted'
        : 'denied'
      const functionality = preferences.acceptedCategories.includes(
        'functionality'
      )
        ? 'granted'
        : 'denied'
      const marketing = preferences.acceptedCategories.includes('marketing')
        ? 'granted'
        : 'denied'

      console.debug('Update consent mode:')
      console.debug('Security consent      :', 'granted')
      console.debug('Funcionality consent  :', functionality)
      console.debug('Analytics consent     :', analytics)
      console.debug('Ads consent consent   :', marketing)

      consent.value = {
        security: true,
        functionality: functionality == 'granted',
        analytics: analytics == 'granted',
        marketing: marketing == 'granted',
      }

      gtag('consent', 'update', {
        security_storage: 'granted',
        personalization_storage: functionality,
        functionality_storage: functionality,
        analytics_storage: analytics,
        ad_storage: marketing,
        ad_user_data: marketing,
        ad_personalization: marketing,
      })

      const { isLoggedIn } = useAuthUser()
      const isLogged = await isLoggedIn()
      if (isLogged) {
        await $fetch('/api/account/cookieconsent/update', {
          method: 'POST',
          body: {
            data: {
              ...preferences,
            },
          },
        })
      }
    },
  }

  await CookieConsent.run(config)

  return {
    provide: {
      CC: CookieConsent,
    },
  }
})
