
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as handlergUYnxrEGq8Meta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/__/auth/handler.vue?macro=true";
import { default as _91_46_46_46seotag_93hLyzUtYA3SMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/[...seotag].vue?macro=true";
import { default as access_45datapXJOE8kR1dMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/access-data.vue?macro=true";
import { default as indexzhKES9TVj5Meta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/addresses/index.vue?macro=true";
import { default as couponsjN8CujjRK5Meta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/coupons.vue?macro=true";
import { default as indexQ8dbURhcH3Meta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/creditcards/index.vue?macro=true";
import { default as favouritesTRmkAiDSfpMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/favourites.vue?macro=true";
import { default as indexLwTtRTZR9bMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/index.vue?macro=true";
import { default as loyalty_45pointsEAlEpcb4LcMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/loyalty-points.vue?macro=true";
import { default as notificationsP9GytSYYbVMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/notifications.vue?macro=true";
import { default as indexbmuV2ij9rGMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/orders/[id]/index.vue?macro=true";
import { default as indexuI5UyHHj9KMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/orders/index.vue?macro=true";
import { default as personal_45dataNfvetmkRRYMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/personal-data.vue?macro=true";
import { default as preferenceswCe2pMzuGXMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/preferences.vue?macro=true";
import { default as addqe2BJSzMwuMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/reviews/add.vue?macro=true";
import { default as stock_45alertsMKo36FlQ4GMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/stock-alerts.vue?macro=true";
import { default as neworyIs4jOscMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/creditcards/new.vue?macro=true";
import { default as braintreemuINLdP6alMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/braintree.vue?macro=true";
import { default as klarna3RcY0Kczz8Meta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/klarna.vue?macro=true";
import { default as multisafepay9jDApQFvjDMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/multisafepay.vue?macro=true";
import { default as _91sessionid_93zCyBsQyBluMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/paycomet/[result]/[sessionid].vue?macro=true";
import { default as _91session_93tA3wItwBgNMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/redsys/[session].vue?macro=true";
import { default as _91result_93pTQGs8TVdbMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/redsys/result/[result].vue?macro=true";
import { default as koOuHuW1MGDoMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/result/ko.vue?macro=true";
import { default as ok5SRiMbeESVMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/result/ok.vue?macro=true";
import { default as challengeeqgjgTZMU0Meta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/test/challenge.vue?macro=true";
import { default as _91id_931FYSqDncFhMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/product/nutritionfacts/[id].vue?macro=true";
import { default as recoveryt6vHhUeOU6Meta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/cart/recovery.vue?macro=true";
import { default as index8YIEv4EmJPMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/checkout/[id]/index.vue?macro=true";
import { default as thankuv1C7uw2KsDMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/checkout/[id]/thanku.vue?macro=true";
import { default as indexb3vMobr0W4Meta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/conocenos/index.vue?macro=true";
import { default as indexZgwjIvp4dLMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/index.vue?macro=true";
import { default as cookie_45policy2XWSx7uBnFMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/legal/cookie-policy.vue?macro=true";
import { default as loginTmu51TlynMMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/login.vue?macro=true";
import { default as indexOSkkowK7xRMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/marcas/index.vue?macro=true";
import { default as _91_46_46_46seotag_93ci85jT1h2HMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/promo/[...seotag].vue?macro=true";
import { default as _91result_93cKmnA8cdgNMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/multisafepay/[session]/[result].vue?macro=true";
import { default as _91result_93c1gqTXi7uUMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/paycomet/[result].vue?macro=true";
import { default as _91session_93v0OusqNWRnMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/[session].vue?macro=true";
import { default as _91result_939vfKpRCyaEMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/result/[result].vue?macro=true";
import { default as recoveryWDEc1sE5vwMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/recovery.vue?macro=true";
import { default as indexHStUM1yZszMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/tiendas/[seotag]/index.vue?macro=true";
import { default as indexna4XZof4UTMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/tiendas/index.vue?macro=true";
import { default as _91_46_46_46slug_93xqsEjbj1saMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/storyblok/draft/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93xRkVThO4mUMeta } from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/storyblok/published/[...slug].vue?macro=true";
export default [
  {
    name: "__-auth-handler",
    path: "/__/auth/handler",
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/__/auth/handler.vue")
  },
  {
    name: "locale-seotag",
    path: "/:locale()/:seotag(.*)*",
    meta: _91_46_46_46seotag_93hLyzUtYA3SMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/[...seotag].vue")
  },
  {
    name: "locale-account-access-data",
    path: "/:locale()/account/access-data",
    meta: access_45datapXJOE8kR1dMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/access-data.vue")
  },
  {
    name: "locale-account-addresses",
    path: "/:locale()/account/addresses",
    meta: indexzhKES9TVj5Meta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/addresses/index.vue")
  },
  {
    name: "locale-account-coupons",
    path: "/:locale()/account/coupons",
    meta: couponsjN8CujjRK5Meta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/coupons.vue")
  },
  {
    name: "locale-account-creditcards",
    path: "/:locale()/account/creditcards",
    meta: indexQ8dbURhcH3Meta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/creditcards/index.vue")
  },
  {
    name: "locale-account-favourites",
    path: "/:locale()/account/favourites",
    meta: favouritesTRmkAiDSfpMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/favourites.vue")
  },
  {
    name: "locale-account",
    path: "/:locale()/account",
    meta: indexLwTtRTZR9bMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/index.vue")
  },
  {
    name: "locale-account-loyalty-points",
    path: "/:locale()/account/loyalty-points",
    meta: loyalty_45pointsEAlEpcb4LcMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/loyalty-points.vue")
  },
  {
    name: "locale-account-notifications",
    path: "/:locale()/account/notifications",
    meta: notificationsP9GytSYYbVMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/notifications.vue")
  },
  {
    name: "locale-account-orders-id",
    path: "/:locale()/account/orders/:id()",
    meta: indexbmuV2ij9rGMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/orders/[id]/index.vue")
  },
  {
    name: "locale-account-orders",
    path: "/:locale()/account/orders",
    meta: indexuI5UyHHj9KMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/orders/index.vue")
  },
  {
    name: "locale-account-personal-data",
    path: "/:locale()/account/personal-data",
    meta: personal_45dataNfvetmkRRYMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/personal-data.vue")
  },
  {
    name: "locale-account-preferences",
    path: "/:locale()/account/preferences",
    meta: preferenceswCe2pMzuGXMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/preferences.vue")
  },
  {
    name: "locale-account-reviews-add",
    path: "/:locale()/account/reviews/add",
    meta: addqe2BJSzMwuMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/reviews/add.vue")
  },
  {
    name: "locale-account-stock-alerts",
    path: "/:locale()/account/stock-alerts",
    meta: stock_45alertsMKo36FlQ4GMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/account/stock-alerts.vue")
  },
  {
    name: "locale-app-webview-creditcards-new",
    path: "/:locale()/app/webview/creditcards/new",
    meta: neworyIs4jOscMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/creditcards/new.vue")
  },
  {
    name: "locale-app-webview-payment-gateway-braintree",
    path: "/:locale()/app/webview/payment/gateway/braintree",
    meta: braintreemuINLdP6alMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/braintree.vue")
  },
  {
    name: "locale-app-webview-payment-gateway-klarna",
    path: "/:locale()/app/webview/payment/gateway/klarna",
    meta: klarna3RcY0Kczz8Meta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/klarna.vue")
  },
  {
    name: "locale-app-webview-payment-gateway-multisafepay",
    path: "/:locale()/app/webview/payment/gateway/multisafepay",
    meta: multisafepay9jDApQFvjDMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/multisafepay.vue")
  },
  {
    name: "locale-app-webview-payment-gateway-paycomet-result-sessionid",
    path: "/:locale()/app/webview/payment/gateway/paycomet/:result()/:sessionid()",
    meta: _91sessionid_93zCyBsQyBluMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/paycomet/[result]/[sessionid].vue")
  },
  {
    name: "locale-app-webview-payment-gateway-redsys-session",
    path: "/:locale()/app/webview/payment/gateway/redsys/:session()",
    meta: _91session_93tA3wItwBgNMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/redsys/[session].vue")
  },
  {
    name: "locale-app-webview-payment-gateway-redsys-result-result",
    path: "/:locale()/app/webview/payment/gateway/redsys/result/:result()",
    meta: _91result_93pTQGs8TVdbMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/gateway/redsys/result/[result].vue")
  },
  {
    name: "locale-app-webview-payment-result-ko",
    path: "/:locale()/app/webview/payment/result/ko",
    meta: koOuHuW1MGDoMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/result/ko.vue")
  },
  {
    name: "locale-app-webview-payment-result-ok",
    path: "/:locale()/app/webview/payment/result/ok",
    meta: ok5SRiMbeESVMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/result/ok.vue")
  },
  {
    name: "locale-app-webview-payment-test-challenge",
    path: "/:locale()/app/webview/payment/test/challenge",
    meta: challengeeqgjgTZMU0Meta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/payment/test/challenge.vue")
  },
  {
    name: "locale-app-webview-product-nutritionfacts-id",
    path: "/:locale()/app/webview/product/nutritionfacts/:id()",
    meta: _91id_931FYSqDncFhMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/app/webview/product/nutritionfacts/[id].vue")
  },
  {
    name: "locale-cart-recovery",
    path: "/:locale()/cart/recovery",
    meta: recoveryt6vHhUeOU6Meta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/cart/recovery.vue")
  },
  {
    name: "locale-checkout-id",
    path: "/:locale()/checkout/:id()",
    meta: index8YIEv4EmJPMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/checkout/[id]/index.vue")
  },
  {
    name: "locale-checkout-id-thanku",
    path: "/:locale()/checkout/:id()/thanku",
    meta: thankuv1C7uw2KsDMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/checkout/[id]/thanku.vue")
  },
  {
    name: "locale-conocenos",
    path: "/:locale()/conocenos",
    meta: indexb3vMobr0W4Meta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/conocenos/index.vue")
  },
  {
    name: "locale",
    path: "/:locale()",
    meta: indexZgwjIvp4dLMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/index.vue")
  },
  {
    name: "locale-legal-cookie-policy",
    path: "/:locale()/legal/cookie-policy",
    meta: cookie_45policy2XWSx7uBnFMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/legal/cookie-policy.vue")
  },
  {
    name: "locale-login",
    path: "/:locale()/login",
    meta: loginTmu51TlynMMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/login.vue")
  },
  {
    name: "locale-marcas",
    path: "/:locale()/marcas",
    meta: indexOSkkowK7xRMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/marcas/index.vue")
  },
  {
    name: "locale-promo-seotag",
    path: "/:locale()/promo/:seotag(.*)*",
    meta: _91_46_46_46seotag_93ci85jT1h2HMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/promo/[...seotag].vue")
  },
  {
    name: "locale-public-checkout-payment-gateway-multisafepay-session-result",
    path: "/:locale()/public/checkout/payment/gateway/multisafepay/:session()/:result()",
    meta: _91result_93cKmnA8cdgNMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/multisafepay/[session]/[result].vue")
  },
  {
    name: "locale-public-checkout-payment-gateway-paycomet-result",
    path: "/:locale()/public/checkout/payment/gateway/paycomet/:result()",
    meta: _91result_93c1gqTXi7uUMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/paycomet/[result].vue")
  },
  {
    name: "locale-public-checkout-payment-gateway-redsys-session",
    path: "/:locale()/public/checkout/payment/gateway/redsys/:session()",
    meta: _91session_93v0OusqNWRnMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/[session].vue")
  },
  {
    name: "locale-public-checkout-payment-gateway-redsys-result-result",
    path: "/:locale()/public/checkout/payment/gateway/redsys/result/:result()",
    meta: _91result_939vfKpRCyaEMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/public/checkout/payment/gateway/redsys/result/[result].vue")
  },
  {
    name: "locale-recovery",
    path: "/:locale()/recovery",
    meta: recoveryWDEc1sE5vwMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/recovery.vue")
  },
  {
    name: "locale-tiendas-seotag",
    path: "/:locale()/tiendas/:seotag()",
    meta: indexHStUM1yZszMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/tiendas/[seotag]/index.vue")
  },
  {
    name: "locale-tiendas",
    path: "/:locale()/tiendas",
    meta: indexna4XZof4UTMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/[locale]/tiendas/index.vue")
  },
  {
    name: "storyblok-draft-slug",
    path: "/storyblok/draft/:slug(.*)*",
    meta: _91_46_46_46slug_93xqsEjbj1saMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/storyblok/draft/[...slug].vue")
  },
  {
    name: "storyblok-published-slug",
    path: "/storyblok/published/:slug(.*)*",
    meta: _91_46_46_46slug_93xRkVThO4mUMeta || {},
    component: () => import("/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/pages/storyblok/published/[...slug].vue")
  }
]