// @see https://support.connectif.ai/hc/es/articles/360014712720-Notificar-evento-de-producto-visitado#h_01J28ZJCVEC1Q4RWGM8KQM0012

export type ConnectifProductData = {
  name: string
  productDetailUrl: string
  productId: string
  unitPrice: number
  availability?: string
  imageUrl: string
  thumbnailUrl?: string
  description?: string
  priority?: number
  rating: number
  reviewCount: number
  tags?: string[]
  categories: string[]
  relatedExternalProductIds?: string[]
  unitPriceOriginal: number
  unitPriceWithoutVAT?: number
  discountedAmount: number
  discountedPercentage: number
  ratingCount?: number
  brand: string
  publishedAt?: string
  attributeId?: string
  customField2?: string
  customField3?: string
}

export interface ConnectifCartProduct extends ConnectifProductData {
  quantity: number
  price: number
}

export interface ConnectifCart {
  cartId: string
  totalQuantity: number
  totalPrice: number
  products: ConnectifCartProduct[]
}

export type ConnectifPageInfo = {
  title: string
  keywords: string[]
  tags: string[]
  categories: string[]
}

declare global {
  interface Window {
    connectif: any // Reemplaza 'any' con el tipo correcto si lo conoces
  }
}

export default function useConnectif(locale: string) {
  const config = useRuntimeConfig()
  const { onLoaded } = useScript(
    {
      src: `https://cdn.connectif.cloud/eu4/client-script/${config.public.CONNECTIF[locale.toUpperCase()].NOTIFICATIONS_PARAM}`,
      referrerpolicy: false,
      crossorigin: false,
    },
    {
      use() {
        return window.connectif
      },
    }
  )

  const sendPageVisit = (pageInfo?: ConnectifPageInfo) => {
    sendEvent([{ type: 'page-visit' }], pageInfo ? { pageInfo } : {})
  }

  const sendProductVisit = (
    productData: ConnectifProductData,
    pageInfo?: ConnectifPageInfo
  ) => {
    sendEvent(
      [
        { type: 'product-visited', product: productData },
        { type: 'page-visit' },
      ],
      pageInfo ? { pageInfo } : {}
    )
  }

  const sendCart = (locale: string, cartData: any) => {
    const cart = buildCartForConnectif(locale, cartData)
    sendEvent([], { cart })
  }

  const sendLogin = () => {
    sendEvent([{ type: 'login' }], {})
  }

  /*
  // https://support.connectif.ai/hc/es/articles/360014699000-Funci%C3%B3n-de-suscripci%C3%B3n-a-notificaciones-web-push
  */
  const subscribeToPush = () => {
    onLoaded(() => {
      if (
        window.connectif &&
        window.connectif.managed &&
        window.connectif.managed.isInitialized()
      ) {
        _subscribe()
      } else {
        document.addEventListener(
          'connectif.managed.initialized',
          function onConnectifInitialized() {
            _subscribe()
          },
          { once: true }
        )
      }
    })
  }

  const sendEvent = (
    events: any[],
    additionalOptions: { cart?: ConnectifCart; pageInfo?: ConnectifPageInfo }
  ) => {
    // https://support.connectif.ai/hc/es/articles/360014698420-Disparo-de-eventos

    onLoaded(() => {
      if (
        window.connectif &&
        window.connectif.managed &&
        window.connectif.managed.isInitialized()
      ) {
        _sendEvent(events, additionalOptions)
      } else {
        document.addEventListener(
          'connectif.managed.initialized',
          function onConnectifInitialized() {
            _sendEvent(events, additionalOptions)
          },
          { once: true }
        )
      }
    })
  }

  return {
    onLoaded,
    sendPageVisit,
    sendProductVisit,
    sendCart,
    sendLogin,
    subscribeToPush,
  }
}

const _sendEvent = (
  events: any[],
  additionalOptions: { cart?: ConnectifCart; pageInfo?: ConnectifPageInfo }
) => {
  const { userData } = useLocalUser()
  const options: {
    entityInfo?: {
      primaryKey: string
      _name: string
      _surname: string
    }
    cart?: ConnectifCart
    pageInfo?: ConnectifPageInfo
  } = { ...additionalOptions }

  if (userData.value) {
    options.entityInfo = {
      primaryKey: userData.value.email,
      _name: userData.value.firstname,
      _surname: userData.value.lastname,
    }
  }

  window.connectif.managed.sendEvents(events, options)
}

const _subscribe = () => {
  window.connectif.subscribeToPushNotifications(function (
    error: any,
    subscription: any
  ) {
    if (error) {
      const nuxtApp = useNuxtApp()
      const { $Sentry } = nuxtApp
      if ($Sentry) {
        $Sentry().captureException(error)
      }
      return console.error(error)
    }
    if (subscription) {
      console.log('The contact has been subscribed successfully')
      // TODO: Enviar el objeto suscription a bbdd para guardar el token de fcm
      /*
      const subscription = {
        endpoint:
          'https://fcm.googleapis.com/fcm/send/cCQFUMtzMf4hdph0-h5kYOWSik5sj990D',
        expirationTime: null,
        keys: {
          auth: 'Lhjk-p4B8977fdeYMDAKi2A==',
          p256dh: 'Hjkjyh79m-kjlH_bz5_bxD2Vk6Zx1ACsmiqHAtW6UrU=',
        },
      }
      */
    } else {
      console.log('The contact has not been subscribed')
    }
  })
}

// TODO FRAN, TIPAR
const buildCartForConnectif = (locale, cart) => {
  const config = useRuntimeConfig()

  const total = cart.totals.find((t) => t.type === 'total').value
  const cartData = {
    cartId: cart.id,
    totalQuantity: cart.count,
    totalPrice: Number(total.toFixed(2)),
    products: [],
  }

  for (const item of cart.items) {
    cartData.products.push({
      productId: `${item.product_id}`,
      brand: item.manufacturer_name,
      name: item.variant_group_name ?? item.short_name,
      unitPrice: Number(item.price.toFixed(2)),
      quantity: item.qty,
      price: Number(item.subtotal.toFixed(2)),
      discountedAmount: item.pvp - item.price,
      discountedPercentage: parseInt(item.badge.replace('%', '')),
      productDetailUrl: `${config.public.APP_URL}/${locale}/${item.seotag}`,
      imageUrl: item.img,
    })
  }
  return cartData
}
