interface LocalUserData {
  email: string
  firstname: string
  lastname: string
  phone?: string
  notification_count?: number
}

interface Geolocation {
  lat: number
  lng: number
}

const useLocalUserData = () => {
  return useState<LocalUserData | null>('localUserData', () => null)
}

const useLocalGeolocation = () => {
  return useState<Geolocation | null>('localGeolocation', () => null)
}

const useLocalCountryPreference = () => {
  return useState<string | null>('localCountryPreference', () => null)
}

export default function useLocalUser() {
  const userData = useLocalUserData()

  const geolocation = useLocalGeolocation()

  const countryPreference = useLocalCountryPreference()

  const setCountryPreference = (country: string) => {
    if (import.meta.server) {
      throw createError(
        'setCountryPreference method not supported on server runtime'
      )
    }

    localStorage.setItem('localCountryPreference', country)
    countryPreference.value = country
    const { setCountryDiscrepancy } = useCountrySelector()
    setCountryDiscrepancy(false)
  }

  const setGeolocation = (data: Geolocation) => {
    localStorage.setItem('localGeolocation', JSON.stringify(data))
    geolocation.value = data
  }

  const setUserData = (data: LocalUserData) => {
    localStorage.setItem('localUserData', JSON.stringify(data))

    userData.value = data
  }

  const deleteUserData = () => {
    localStorage.removeItem('localUserData')
    userData.value = null
  }

  return {
    userData: readonly(userData),
    geolocation: readonly(geolocation),

    countryPreference: readonly(countryPreference),

    setUserData,
    deleteUserData,
    setGeolocation,
    setCountryPreference,
  }
}
