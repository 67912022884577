const countryNamesByLocale: { [key: string]: { [key: string]: string } } = {
  es: {
    es: 'España',
    fr: 'Francia',
    de: 'Alemania',
    it: 'Italia',
    pt: 'Portugal',
    nl: 'Países Bajos',
    hu: 'Hungría',
    cz: 'República Checa',
    pl: 'Polonia',
    dk: 'Dinamarca',
    fi: 'Finlandia',
    gr: 'Grecia',
    no: 'Noruega',
    ro: 'Rumanía',
    sk: 'Eslovaquia',
    se: 'Suecia',
    ch: 'Suiza',
    tr: 'Turquía',
    si: 'Eslovenia',
  },
  fr: {
    es: 'Espagne',
    fr: 'France',
    de: 'Allemagne',
    it: 'Italie',
    pt: 'Portugal',
    nl: 'Pays-Bas',
    hu: 'Hongrie',
    cz: 'République tchèque',
    pl: 'Pologne',
    dk: 'Danemark',
    fi: 'Finlande',
    gr: 'Grèce',
    no: 'Norvège',
    ro: 'Roumanie',
    sk: 'Slovaquie',
    se: 'Suède',
    ch: 'Suisse',
    tr: 'Turquie',
    si: 'Slovénie',
  },
  de: {
    es: 'Spanien',
    fr: 'Frankreich',
    de: 'Deutschland',
    it: 'Italien',
    pt: 'Portugal',
    nl: 'Niederlande',
    hu: 'Ungarn',
    cz: 'Tschechien',
    pl: 'Polen',
    dk: 'Dänemark',
    fi: 'Finnland',
    gr: 'Griechenland',
    no: 'Norwegen',
    ro: 'Rumänien',
    sk: 'Slowakei',
    se: 'Schweden',
    ch: 'Schweiz',
    tr: 'Türkei',
    si: 'Slowenien',
  },
  it: {
    es: 'Spagna',
    fr: 'Francia',
    de: 'Germania',
    it: 'Italia',
    pt: 'Portogallo',
    nl: 'Paesi Bassi',
    hu: 'Ungheria',
    cz: 'Repubblica Ceca',
    pl: 'Polonia',
    dk: 'Danimarca',
    fi: 'Finlandia',
    gr: 'Grecia',
    no: 'Norvegia',
    ro: 'Romania',
    sk: 'Slovacchia',
    se: 'Svezia',
    ch: 'Svizzera',
    tr: 'Turchia',
    si: 'Slovenia',
  },
  pt: {
    es: 'Espanha',
    fr: 'França',
    de: 'Alemanha',
    it: 'Itália',
    pt: 'Portugal',
    nl: 'Países Baixos',
    hu: 'Hungria',
    cz: 'República Checa',
    pl: 'Polônia',
    dk: 'Dinamarca',
    fi: 'Finlândia',
    gr: 'Grécia',
    no: 'Noruega',
    ro: 'Romênia',
    sk: 'Eslováquia',
    se: 'Suécia',
    ch: 'Suíça',
    tr: 'Turquia',
    si: 'Eslovênia',
  },
  be: {
    es: 'Espagne',
    fr: 'France',
    de: 'Allemagne',
    it: 'Italie',
    pt: 'Portugal',
    nl: 'Pays-Bas',
    hu: 'Hongrie',
    cz: 'République tchèque',
    pl: 'Pologne',
    dk: 'Danemark',
    fi: 'Finlande',
    gr: 'Grèce',
    no: 'Norvège',
    ro: 'Roumanie',
    sk: 'Slovaquie',
    se: 'Suède',
    ch: 'Suisse',
    tr: 'Turquie',
    si: 'Slovénie',
  },
  at: {
    es: 'Spanien',
    fr: 'Frankreich',
    de: 'Deutschland',
    it: 'Italien',
    pt: 'Portugal',
    nl: 'Niederlande',
    hu: 'Ungarn',
    cz: 'Tschechien',
    pl: 'Polen',
    dk: 'Dänemark',
    fi: 'Finnland',
    gr: 'Griechenland',
    no: 'Norwegen',
    ro: 'Rumänien',
    sk: 'Slowakei',
    se: 'Schweden',
    ch: 'Schweiz',
    tr: 'Türkei',
    si: 'Slowenien',
  },
  nl: {
    es: 'Spanje',
    fr: 'Frankrijk',
    de: 'Duitsland',
    it: 'Italië',
    pt: 'Portugal',
    nl: 'Nederland',
    hu: 'Hongarije',
    cz: 'Tsjechië',
    pl: 'Polen',
    dk: 'Denemarken',
    fi: 'Finland',
    gr: 'Griekenland',
    no: 'Noorwegen',
    ro: 'Roemenië',
    sk: 'Slowakije',
    se: 'Zweden',
    ch: 'Zwitserland',
    tr: 'Turkije',
    si: 'Slovenië',
  },
  hu: {
    es: 'Spanyolország',
    fr: 'Franciaország',
    de: 'Németország',
    it: 'Olaszország',
    pt: 'Portugália',
    nl: 'Hollandia',
    hu: 'Magyarország',
    cz: 'Csehország',
    pl: 'Lengyelország',
    dk: 'Dánia',
    fi: 'Finnország',
    gr: 'Görögország',
    no: 'Norvégia',
    ro: 'Románia',
    sk: 'Szlovákia',
    se: 'Svédország',
    ch: 'Svájc',
    tr: 'Törökország',
    si: 'Szlovénia',
  },
  cz: {
    es: 'Španělsko',
    fr: 'Francie',
    de: 'Německo',
    it: 'Itálie',
    pt: 'Portugalsko',
    nl: 'Holandsko',
    hu: 'Maďarsko',
    cz: 'Česká republika',
    pl: 'Polsko',
    dk: 'Dánsko',
    fi: 'Finsko',
    gr: 'Řecko',
    no: 'Norsko',
    ro: 'Rumunsko',
    sk: 'Slovensko',
    se: 'Švédsko',
    ch: 'Švýcarsko',
    tr: 'Turecko',
    si: 'Slovinsko',
  },
  pl: {
    es: 'Hiszpania',
    fr: 'Francja',
    de: 'Niemcy',
    it: 'Włochy',
    pt: 'Portugalia',
    nl: 'Holandia',
    hu: 'Węgry',
    cz: 'Czechy',
    pl: 'Polska',
    dk: 'Dania',
    fi: 'Finlandia',
    gr: 'Grecja',
    no: 'Norwegia',
    ro: 'Rumunia',
    sk: 'Słowacja',
    se: 'Szwecja',
    ch: 'Szwajcaria',
    tr: 'Turcja',
    si: 'Słowenia',
  },
  dk: {
    es: 'Spanien',
    fr: 'Frankrig',
    de: 'Tyskland',
    it: 'Italien',
    pt: 'Portugal',
    nl: 'Holland',
    hu: 'Ungarn',
    cz: 'Tjekkiet',
    pl: 'Polen',
    dk: 'Danmark',
    fi: 'Finland',
    gr: 'Grækenland',
    no: 'Norge',
    ro: 'Rumænien',
    sk: 'Slovakiet',
    se: 'Sverige',
    ch: 'Schweiz',
    tr: 'Tyrkiet',
    si: 'Slovenien',
  },
  fi: {
    es: 'Espanja',
    fr: 'Ranska',
    de: 'Saksa',
    it: 'Italia',
    pt: 'Portugali',
    nl: 'Hollanti',
    hu: 'Unkari',
    cz: 'Tšekin tasavalta',
    pl: 'Puola',
    dk: 'Tanska',
    fi: 'Suomi',
    gr: 'Kreikka',
    no: 'Norja',
    ro: 'Romania',
    sk: 'Slovakia',
    se: 'Ruotsi',
    ch: 'Sveitsi',
    tr: 'Turkkiye',
    si: 'Slovenia',
  },
  gr: {
    es: 'Ισπανία',
    fr: 'Γαλλία',
    de: 'Γερμανία',
    it: 'Ιταλία',
    pt: 'Πορτογαλία',
    nl: 'Ολλανδία',
    hu: 'Ουγγαρία',
    cz: 'Τσεχική Δημοκρατία',
    pl: 'Πολωνία',
    dk: 'Δανία',
    fi: 'Φινλανδία',
    gr: 'Ελλάδα',
    no: 'Νορβηγία',
    ro: 'Ρουμανία',
    sk: 'Σλοβακία',
    se: 'Σουηδία',
    ch: 'Ελβετός',
    tr: 'Τουρκία',
    si: 'Σλοβενία',
  },
  no: {
    es: 'Spania',
    fr: 'Frankrike',
    de: 'Tyskland',
    it: 'Italia',
    pt: 'Portugal',
    nl: 'Holland',
    hu: 'Ungarn',
    cz: 'Tsjekkia',
    pl: 'Polen',
    dk: 'Danmark',
    fi: 'Finland',
    gr: 'Hellas',
    no: 'Norge',
    ro: 'Romania',
    sk: 'Slovakia',
    se: 'Sverige',
    ch: 'Sveitsisk',
    tr: 'Tyrkiye',
    si: 'Slovenia',
  },
  ro: {
    es: 'Spania',
    fr: 'Franţa',
    de: 'Germania',
    it: 'Italia',
    pt: 'Portugalia',
    nl: 'Olanda',
    hu: 'Ungaria',
    cz: 'Republica Cehă',
    pl: 'Polonia',
    dk: 'Danemarca',
    fi: 'Finlandia',
    gr: 'Grecia',
    no: 'Norvegia',
    ro: 'România',
    sk: 'Slovacia',
    se: 'Suedia',
    ch: 'Elveţia',
    tr: 'Turcia',
    si: 'Slovenia',
  },
  sk: {
    es: 'Španielsko',
    fr: 'Francúzsko',
    de: 'Nemecko',
    it: 'Taliansko',
    pt: 'Portugalsko',
    nl: 'Holandsko',
    hu: 'Maďarsko',
    cz: 'Česká republika',
    pl: 'Poľsko',
    dk: 'Dánsko',
    fi: 'Fínsko',
    gr: 'Grécko',
    no: 'Nórsko',
    ro: 'Rumunsko',
    sk: 'Slovensko',
    se: 'Švédsko',
    ch: 'Švajčiarsko',
    tr: 'Türkiye',
    si: 'Slovinsko',
  },
  se: {
    es: 'Spanien',
    fr: 'Frankrike',
    de: 'Tyskland',
    it: 'Italien',
    pt: 'Portugal',
    nl: 'Holland',
    hu: 'Ungern',
    cz: 'Tjeckien',
    pl: 'Polen',
    dk: 'Danmark',
    fi: 'Finland',
    gr: 'Grekland',
    no: 'Norge',
    ro: 'Rumänien',
    sk: 'Slovakien',
    se: 'Sverige',
    ch: 'Schweiz',
    tr: 'Turkiet',
    si: 'Slovenia',
  },
  ch: {
    es: 'Espagne',
    fr: 'France',
    de: 'Allemagne',
    it: 'Italie',
    pt: 'Portugal',
    nl: 'Pays-Bas',
    hu: 'Hongrie',
    cz: 'République tchèque',
    pl: 'Pologne',
    dk: 'Danemark',
    fi: 'Finlande',
    gr: 'Grèce',
    no: 'Norvège',
    ro: 'Roumanie',
    sk: 'Slovaquie',
    se: 'Suède',
    ch: 'Suisse',
    tr: 'Turquie',
    si: 'Slovénie',
  },
  tr: {
    es: 'İspanya',
    fr: 'Fransa',
    de: 'Almanya',
    it: 'İtalya',
    pt: 'Portekiz',
    nl: 'Hollanda',
    hu: 'Macaristan',
    cz: 'Çek Cumhuriyeti',
    pl: 'Polonya',
    dk: 'Danimarka',
    fi: 'Finlandiya',
    gr: 'Yunanistan',
    no: 'Norveç',
    ro: 'Romanya',
    sk: 'Slovakya',
    se: 'İsveç',
    ch: 'İsviçre',
    tr: 'Türkiye',
    si: 'Slovenya',
  },
  si: {
    es: 'Španija',
    fr: 'Francija',
    de: 'Nemčija',
    it: 'Italija',
    pt: 'Portugalska',
    nl: 'Nizozemska',
    hu: 'Madžarska',
    cz: 'Češka',
    pl: 'Poljska',
    dk: 'Danska',
    fi: 'Finska',
    gr: 'Grčija',
    no: 'Norveška',
    ro: 'Romunija',
    sk: 'Slovaška',
    se: 'Švedska',
    ch: 'Švica',
    tr: 'Turčija',
    si: 'Slovenija',
  },
}

export default function useLocaleManager() {
  const getLocalePaths = () => {
    return [
      '/es',
      '/pt',
      '/fr',
      '/de',
      '/it',
      '/be',
      '/at',
      // '/nl',
      // '/hu',
      // '/cz',
      // '/pl',
      // '/dk',
      // '/fi',
      // '/gr',
      // '/no',
      // '/ro',
      // '/sk',
      // '/se',
      // '/ch',
      // '/tr',
      // '/si',
    ]
  }

  const getSupportedLocales = (): Array<string> => {
    return [
      'es',
      'pt',
      'fr',
      'de',
      'it',
      'be',
      'at',
      // 'nl',
      // 'hu',
      // 'cz',
      // 'pl',
      // 'dk',
      // 'fi',
      // 'gr',
      // 'no',
      // 'ro',
      // 'sk',
      // 'se',
      // 'ch',
      // 'tr',
      // 'si',
    ]
  }

  const getCountryNameByLocale = (
    locale: string,
    localeToTranslate: string
  ) => {
    return countryNamesByLocale[localeToTranslate][locale]
  }

  return {
    getLocalePaths,
    getSupportedLocales,
    getCountryNameByLocale,
  }
}
