
// @ts-nocheck


export const localeCodes =  [
  "es",
  "pt",
  "fr",
  "de",
  "it",
  "nl",
  "hu",
  "cz",
  "pl",
  "dk",
  "fi",
  "gr",
  "no",
  "ro",
  "sk",
  "se",
  "ch",
  "tr",
  "si"
]

export const localeLoaders = {
  es: [],
  pt: [],
  fr: [],
  de: [],
  it: [],
  nl: [],
  hu: [],
  cz: [],
  pl: [],
  dk: [],
  fi: [],
  gr: [],
  no: [],
  ro: [],
  sk: [],
  se: [],
  ch: [],
  tr: [],
  si: []
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "es",
      iso: "es-ES"
    },
    {
      code: "pt",
      iso: "pt-PT"
    },
    {
      code: "fr",
      iso: "fr-FR"
    },
    {
      code: "de",
      iso: "de-DE"
    },
    {
      code: "it",
      iso: "it-IT"
    },
    {
      code: "nl",
      iso: "nl-NL"
    },
    {
      code: "hu",
      iso: "hu-HU"
    },
    {
      code: "cz",
      iso: "cz-CZ"
    },
    {
      code: "pl",
      iso: "pl-PL"
    },
    {
      code: "dk",
      iso: "dk-DK"
    },
    {
      code: "fi",
      iso: "fi-FI"
    },
    {
      code: "gr",
      iso: "gr-GR"
    },
    {
      code: "no",
      iso: "no-NO"
    },
    {
      code: "ro",
      iso: "ro-RO"
    },
    {
      code: "sk",
      iso: "sk-SK"
    },
    {
      code: "se",
      iso: "se-SE"
    },
    {
      code: "ch",
      iso: "ch-CH"
    },
    {
      code: "tr",
      iso: "tr-TR"
    },
    {
      code: "si",
      iso: "si-SI"
    }
  ],
  defaultLocale: "es",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: false,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "es",
    iso: "es-ES",
    files: []
  },
  {
    code: "pt",
    iso: "pt-PT",
    files: []
  },
  {
    code: "fr",
    iso: "fr-FR",
    files: []
  },
  {
    code: "de",
    iso: "de-DE",
    files: []
  },
  {
    code: "it",
    iso: "it-IT",
    files: []
  },
  {
    code: "nl",
    iso: "nl-NL",
    files: []
  },
  {
    code: "hu",
    iso: "hu-HU",
    files: []
  },
  {
    code: "cz",
    iso: "cz-CZ",
    files: []
  },
  {
    code: "pl",
    iso: "pl-PL",
    files: []
  },
  {
    code: "dk",
    iso: "dk-DK",
    files: []
  },
  {
    code: "fi",
    iso: "fi-FI",
    files: []
  },
  {
    code: "gr",
    iso: "gr-GR",
    files: []
  },
  {
    code: "no",
    iso: "no-NO",
    files: []
  },
  {
    code: "ro",
    iso: "ro-RO",
    files: []
  },
  {
    code: "sk",
    iso: "sk-SK",
    files: []
  },
  {
    code: "se",
    iso: "se-SE",
    files: []
  },
  {
    code: "ch",
    iso: "ch-CH",
    files: []
  },
  {
    code: "tr",
    iso: "tr-TR",
    files: []
  },
  {
    code: "si",
    iso: "si-SI",
    files: []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/