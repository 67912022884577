import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(async (nuxtApp) => {
  if (import.meta.server) {
    const requestCountry = (
      useRequestHeader('CF-IPCountry') ?? 'es'
    ).toLowerCase()
    const { setCountryDetected } = useCountrySelector()

    const { getSupportedLocales } = useLocaleManager()
    const country = getSupportedLocales().includes(requestCountry)
      ? requestCountry
      : 'es'

    setCountryDetected(country)
  }

  const route = useRoute()
  const { getSupportedLocales } = useLocaleManager()
  const supportedLocales = getSupportedLocales()
  const locale = supportedLocales.includes(route.params.locale)
    ? route.params.locale
    : 'es'

  nuxtApp.$i18n.locale.value = locale

  // añadir al html lang y locale
  useHead({
    htmlAttrs: {
      lang: `${locale}`,
    },
  })
})
