import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin({
  name: 'user',
  parallel: true,
  setup(nuxtApp) {
    const { setTotalItems } = useCart()
    const items = Number(localStorage.getItem('cartTotalItems') ?? 0)
    setTotalItems(items)

    const userData = localStorage.getItem('localUserData')
    if (userData) {
      const { setUserData } = useLocalUser()
      setUserData(JSON.parse(userData))
    }

    const geolocation = localStorage.getItem('localGeolocation')
    if (geolocation) {
      const { setGeolocation } = useLocalUser()
      setGeolocation(JSON.parse(geolocation))
    }

    const countryPreference = localStorage.getItem('localCountryPreference')
    if (countryPreference) {
      const { setCountryPreference } = useLocalUser()
      setCountryPreference(countryPreference)
    }
  },
})
