import revive_payload_client_4sVQNw7RlN from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KlVwwuJRCL from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import plugin_ghbUAjaD3n from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_OVoKJro5pc from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import _00_sentry_client_3cjTwoKJ1L from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/plugins/00.sentry.client.ts";
import _01_i18n_C0VqlaT7Ms from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/plugins/01.i18n.ts";
import _02_gtag_client_VlHiRb8oWj from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/plugins/02.gtag.client.ts";
import _03_cookieconsent_client_PD6wDv7EIv from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/plugins/03.cookieconsent.client.ts";
import _03_user_client_0zxiGtHCSp from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/plugins/03.user.client.ts";
import _04_loading_client_CA8o39wVJc from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/plugins/04.loading.client.ts";
import _05_doofinder_client_vdSfLWbPY7 from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/plugins/05.doofinder.client.ts";
import _06_scrollToTop_client_FAPYKWFYRF from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/plugins/06.scrollToTop.client.ts";
import _07_facebook_client_MCsSq8VMJV from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/plugins/07.facebook.client.ts";
import _08_countrypopup_client_4cTYrRDjoM from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/plugins/08.countrypopup.client.ts";
import ssg_detect_3fHkBxLtv0 from "/Users/franciscoiglesiasmella/Desktop/nuxt-frontend-web-app/.sanremo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_KlVwwuJRCL,
  plugin_ghbUAjaD3n,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  plugin_client_OVoKJro5pc,
  _00_sentry_client_3cjTwoKJ1L,
  _01_i18n_C0VqlaT7Ms,
  _02_gtag_client_VlHiRb8oWj,
  _03_cookieconsent_client_PD6wDv7EIv,
  _03_user_client_0zxiGtHCSp,
  _04_loading_client_CA8o39wVJc,
  _05_doofinder_client_vdSfLWbPY7,
  _06_scrollToTop_client_FAPYKWFYRF,
  _07_facebook_client_MCsSq8VMJV,
  _08_countrypopup_client_4cTYrRDjoM,
  ssg_detect_3fHkBxLtv0
]