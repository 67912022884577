export const useCountrySelectorState = () => {
  return useState<boolean>('countrySelectorState', () => false)
}

const useLocalCountryDetected = () => {
  return useState<string | null>('localCountryDetected', () => null)
}

const useLocalCountryDiscrepancy = () => {
  return useState<boolean>('localCountryDiscrepancy', () => false)
}

export default function useCountrySelector() {
  const countrySelector = useCountrySelectorState()

  const countryDetected = useLocalCountryDetected()

  const countryDiscrepancy = useLocalCountryDiscrepancy()

  const setCountryDiscrepancy = (value: boolean) => {
    if (import.meta.server) {
      throw createError(
        'setCountryDiscrepancy method not supported on server runtime'
      )
    }
    countryDiscrepancy.value = value
  }

  const setCountryDetected = (country: string) => {
    if (import.meta.client) {
      throw createError(
        'setCountryDetected method not supported on client runtime'
      )
    }
    countryDetected.value = country
  }

  return {
    countrySelector: readonly(countrySelector),
    countryDiscrepancy: readonly(countryDiscrepancy),
    countryDetected: readonly(countryDetected),

    setCountryDetected,
    setCountryDiscrepancy,
  }
}
