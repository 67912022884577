export default defineNuxtPlugin(() => {
  const { countryPreference, setCountryPreference } = useLocalUser()
  const { countryDetected, setCountryDiscrepancy } = useCountrySelector()

  const route = useRoute()
  if (route.params.locale == countryDetected.value) {
    setCountryPreference(countryDetected.value)
  } else if (route.params.locale !== countryPreference.value) {
    setCountryDiscrepancy(true)
  }
})
